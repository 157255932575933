import barba from '@barba/core'
import aos from './components/aos'
import activeNav from './components/active-nav'
import cursorButton from './components/cursor-button'
import video from './components/video'
import gallery from './components/gallery'
import moreWork from './components/more-work'
import clientsGallery from './components/clients-gallery'
import scrollMarquee from './components/scroll-marquee'
import contentCarousel from './components/content-carousel'

barba.hooks.beforeEnter(({next}) => {
	aos(next.container)
	activeNav(next.url.path)
	video(next.container)
	gallery(next.container)
	moreWork(next.container)
	cursorButton(next.container)
	clientsGallery(next.container)
	scrollMarquee(next.container)
	contentCarousel(next.container)
})

barba.hooks.afterLeave(({next}) => {
	let cursorButton = document.querySelector('.cursor-button')
	cursorButton.remove()
})

// import all transitions in the barba transitions folder
const transitions = []
const t = require.context('./transitions/', false, /\.js$/)
t.keys().forEach(key => transitions.push(t(key).default))

// import all views in the barba views folder
const views = []
const v = require.context('./views/', false, /\.js$/)
v.keys().forEach(key => views.push(v(key).default))

barba.init({
	transitions,
	views,
	timeout: 5000,
	debug: true
})
