<template lang="pug">

	header(:class="{ 'active' : storeState.mobileNavActive }")
		a.sticky-logo(href="/" alt="Floodlight")
			//- include ../../../pug/partials/logo
			img(src="../../../../public/img/logo.gif" alt="Floodlight" title="Floodlight")

		.nav-toggle(@click="toggleNav")
			span.top
			span.bot

		nav.desktop-nav(v-if="nav")
			a.nav-link(v-for="item in nav" :href="item.url" :class="{ 'active' : item.active }") {{ item.title }}

</template>

<script>
import { store } from "../store.js"

export default {
	name: 'Header',
	el: 'site-header',
	data() {
		return {
			nav: null,
			storeState: store.state
		}
	},
	methods: {
		toggleNav() {
			store.mutators.setMobileNavActive(!this.storeState.mobileNavActive)
		}
	},
	beforeMount() {
		this.nav = window.site.nav
	}
};
</script>
