<template lang="pug">

	nav.mobile-nav(ref="nav" v-if="nav")
		template(v-if="!storeState.mobileNavAnimating" )
			.nav-link(v-for="item in nav" ref="links" :class="{ 'active' : item.active }" @click="toggleNav()") 
				a(:href="item.url") {{ item.title }}
		template(v-else)
			.nav-link(v-for="item in nav" ref="links" :class="{ 'active' : item.active }") {{ item.title }}
		a.underline.nav-start-project(ref="startProject" href="/contact") Start a project

</template>

<script>
import { store } from "../store.js"

import { gsap } from 'gsap';
import springer from 'springer'
const spring = springer(0.8, 0.2)
const bounce = springer(0.4, 0.6)

export default {
	name: 'MobileNav',
	el: 'mobile-nav',
	data() {
		return {
			nav: null,
			navTween: gsap.timeline({ paused: true }),
			storeState: store.state
		}
	},
	methods: {
		toggleNav() {
			console.log('clicked ToggleNav')
			store.mutators.setMobileNavActive(!this.storeState.mobileNavActive)
		},
		openNav() {
			this.navTween
				.to(this.$refs.nav, { x: '0%', ease: spring, duration: 0.5, 
					onComplete: function() {
						store.mutators.setMobileNavAnimating(false)
					}
				})
				.from(this.$refs.links, { x: '100%', scale: '1', autoAlpha: 0, force3D: true, ease: bounce, stagger: 0.03, duration: 1, delay: -0.25})
				.to(this.$refs.startProject, { y: '0', force3D: true, autoAlpha: 1, ease: bounce, duration: 1, delay: -0.75})

		},
		closeNav() {
			this.navTween
				.to(this.$refs.startProject, { y: '15%', force3D: true, autoAlpha: 0, ease: bounce, duration: 1 })
				.to(this.$refs.links, { x: '-100%', scale: '0.9', autoAlpha: 0, force3D: true, ease: bounce, stagger: 0.03, duration: 1, delay: -0.75 })
				.to(this.$refs.nav, { x: '-100%', ease: spring, duration: 0.5, delay: -0.75 })
				.set(this.$refs.nav, { x: '100%' })
				.set(this.$refs.links, { x: '0%', autoAlpha: 1 })
				.set(this.$refs.startProject, { y: '15%', autoAlpha: 0 , 
					onComplete: function() {
						store.mutators.setMobileNavAnimating(false)
					}
				})
		},
	},
	beforeMount() {
		this.nav = window.site.nav
		window.addEventListener('nav-changed', (e) => { this.storeState.mobileNavActive ? this.openNav() : this.closeNav() }, false);
	},
	mounted() {
		this.navTween
			.set(this.$refs.nav, { x: '100%' })
			.set(this.$refs.startProject, { y: '15%', autoAlpha: 0 })
		this.navTween.play()
	}
};
</script>
