import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

let htl, page, showreel, introVideo

export default {
	namespace: 'home',
	beforeEnter() {

	},
	afterEnter(barba){

		page = barba.next.container.querySelector('#homeWrapper')
		showreel = barba.next.container.querySelector('.showreel')
		introVideo = barba.next.container.querySelector('.video-element iframe')

		const player = Stream(document.getElementById(introVideo.id))

		ScrollTrigger.matchMedia({
	
			"(min-width: 768px)": function() { // Start Match Media

				htl = gsap.timeline({
					scrollTrigger: {
						trigger: page,
						pin: true,
						start: "top top",
						end: "+=100%",
						scrub: 1,
						// markers: {startColor: "green", endColor: "red", fontSize: "12px"},
						snap: {
							snapTo: 'labels',
							duration: 0.2,
							delay: 0.1
						},
						onSnapComplete: ({direction}) => {
							if(direction > 0) {
								console.log("PLAY")
								player.play().catch(() => {
									console.log('playback failed, muting to try again')
									player.muted = true
									player.play()
								})
							} else {
								console.log("PAUSE")
								player.pause()
								player.currentTime = 0
							}
						},
					}
				})
				.addLabel("start")
				.from(showreel, {
					x: '92%',
					scale: 0.9,
					duration: 1
				})
				.addLabel('finish')

				return function() {
					console.log('killing timeline no media match')
					htl.kill();
					showreel.removeAttribute('style') // manual cleanup
					// other cleanup code can go here. 
				};

			} // End Match Media
		});

	},
	afterLeave() {

		let ScrollTriggers = ScrollTrigger.getAll()
		if (ScrollTriggers.length > 0)
			ScrollTriggers.forEach(st => {
				st.kill(true)
			})
			

	}
}
