import { gsap } from "gsap";

const cursorButtons = function(next) {

	// Search for any cursor-buttons on current page...
	let cursorButton = document.querySelectorAll('.cursor-button')
	let cursorWrappers = next.querySelectorAll('[data-cursor-button]')

	let button

	if (cursorButton.length == 0 && cursorWrappers) {
		// Create cursor dom el...
		button = document.createElement('div'),
		button.classList.add('cursor-button')
		document.body.appendChild(button)

		// document.addEventListener('pointermove', e => {
		// 	makeCursorButton(e, button)
		// })
	} else {
		button = document.querySelector('.cursor-button')
	}

	// Loop through each cursor-button el...
	for (let wrapper of cursorWrappers) {

		let colour = wrapper.dataset.buttonColor,
			text = wrapper.dataset.buttonText

		wrapper.addEventListener('mouseover', e => {
			button.innerHTML = text
			button.classList.add('active', 'bg-' + colour)
		})

		wrapper.addEventListener('pointermove', e => {
			makeCursorButton(e, button)
		})
		
		wrapper.addEventListener('mouseout', e => {
			button.classList.remove('active', 'bg-' + colour)
		})
	}

	// Use GSAP to follow cursor smoothly...
	function makeCursorButton(e, button) {
		// console.log
		let left = (e.pageX - (button.offsetWidth / 2)) + 'px'
		let top = (e.pageY - (button.offsetHeight / 2)) + 'px'
		gsap.to(button, {
			css: {
				left: left,
				top: top
			},
			duration: 0.2
		});
	}

}

export default cursorButtons
