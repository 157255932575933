import Swiper from 'swiper';

const contentCarousel = function(next) {

	// Search for any more work galleries on current page...
	let carousels = document.getElementsByClassName('content-carousel')

	// Loop through each gallery el...
	for (let carousel of carousels) {

		let slider = carousel.querySelector('.swiper-container')

		let swiper = new Swiper(slider, {
			slidesPerView: "auto",
			centeredSlides: false
		})

	}

}

export default contentCarousel
