const store = {
	state: {
		mobileNavActive: false,
		mobileNavAnimating: false
	},
	mutators: {
		setMobileNavActive: function(val) {
			if (!store.state.mobileNavAnimating) {
				store.mutators.setMobileNavAnimating(true)
				store.state.mobileNavActive = val
				window.dispatchEvent(new Event('nav-changed', { detail: val }))
			} else {
				console.log('We are animating...')
			}
		},
		setMobileNavAnimating: function(val) {
			store.state.mobileNavAnimating = val
		}
	}
}
export { store }
