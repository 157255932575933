const axios = require('axios');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default {
	namespace: 'contact',
	beforeEnter() {

	},
	afterEnter(barba) {

		let form = barba.next.container.querySelector('.contact-form form'),
			submitBtn = form.querySelector('button'),
			formMessage = form.querySelector('.form-message')

		form.addEventListener("submit", (event) => {

			event.preventDefault() // don't submit manually...
			submitBtn.innerHTML = 'Please Wait...'
			submitBtn.setAttribute('disabled', true)
			submitBtn.classList.add('button-loading')

			axios
				.post(form.action, new FormData(form))
				.then(response => {
					submitBtn.remove()
					formMessage.innerHTML = 'Thank you, your message has been sent.'
				})
				.catch(function (error) {
					submitBtn.innerHTML = 'Error'
					formMessage.innerHTML = 'Something has gone wrong, please try again.'
					// handle error
					console.error(error);
				})
				.then(function () {
					// always executed
					console.log('Form submitted...')
				});

		})
		

	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
