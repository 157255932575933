
export default () => ({
	filterItems: [
		{
			"slug": "all",
			"title": "All",
			"active": true,
		}
	],
	filterGrid: false,
	toggleFilter(slug) {
		this.filterItems.forEach(item => {
			item.active = false
			if (item.slug == slug) {
				item.active = true
			}
		})

		// Update the URL
		let newUrl;
		if (slug === "all") {
			newUrl = window.location.protocol + "//" + window.location.host + "/work";
		} else {
			newUrl = window.location.protocol + "//" + window.location.host + "/work/filter/" + slug;
		}
		window.history.pushState({ path: newUrl }, '', newUrl);

	},
	init() {

		window.work.category_filters.forEach(item => {
			this.filterItems.push(item)
		})

		// on load filter if the url path has /category/ in it we need to get the category from the url after the last slash and then filter by that.
		let url = new URL(window.location.href)
		let path = url.pathname
		if (path.includes('/filter/')) {
			let filter = path.split('/').pop()
			if (filter) {
				this.toggleFilter(filter)
			}
		}



	},
});
