import Swiper from 'swiper';

const clientsGallery = function(next) {

	//- Search for any galleries on current page...
	let clientsGalleryComponent = next.querySelectorAll("[data-type='clients-gallery']"),
		clientsGallery = next.querySelector(".client-list"),
		clientsCounter = next.querySelector(".client-list-counter"),
		clientsTotal = next.querySelector(".client-list-total"),
		clientsLogos = next.querySelectorAll(".client-list-logo"),
		clientsQuotes = next.querySelectorAll(".client-list-quote"),
		randomQuoteInterval = 3000;

	if (clientsGalleryComponent.length == 0) return // Only continue if Clients Gallery Component is found...

	clientsLogos.forEach(logo => {
		logo.addEventListener("click", (event) => {
			// console.log('click event', event)
			stopQuotePicker();
			setActiveQuote(event.target.dataset.index);
			setActiveLogo(event.target.dataset.index);
		});
	});

	const formattedNumber = (number) => {
		return number.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false
		});
	}

	const setActiveQuote = (index) => {
		// console.log(index)
		clientsQuotes.forEach(quote => {
			quote.classList.remove('active')
		});
		// console.log('active indecx', clientsQuotes[index])
		clientsQuotes[index].classList.add("active");
	}

	const setActiveLogo = index => {
		clientsLogos.forEach(logo => {
			logo.classList.remove("active");
		});
		clientsLogos[index].classList.add("active");
	};

	//- Setup Swiper var to reuse later...
	let mySwiper

	// - Setup Swiper function...
	const enableSwiper = () => {
		mySwiper = new Swiper(clientsGallery, {
			wrapperClass: "client-list-wrapper",
			slideClass: "client-list-logo",
			slideActiveClass: "active",
			slidesPerView: "auto",
			spaceBetween: 7,
			centeredSlides: false,
			autoplay: true,
			loop: true,
			breakpoints: {
				640: {
					spaceBetween: 30
				}
			},
			on: {
				init: function(event) {
					setActiveQuote(event.realIndex);
					clientsTotal.innerHTML = formattedNumber(event.loopedSlides);
					clientsCounter.innerHTML = formattedNumber(event.realIndex + 1);
				},
				slideChange: function(event) {
					setActiveQuote(event.realIndex);
					clientsCounter.innerHTML = formattedNumber(event.realIndex + 1);
				}
			}
		});
	}

	let randomQuoteTimer

	let randomQuotePicker = () => {
		let randomQuoteIndex = Math.floor(Math.random() * clientsQuotes.length);
		setActiveQuote(randomQuoteIndex);
		setActiveLogo(randomQuoteIndex);
	}

	let stopQuotePicker = () => {
		clearInterval(randomQuoteTimer);
		randomQuoteTimer = null;
	}

	//- Setup breakpoints to create/destroy Swipers...
	const mediaQuery = window.matchMedia('(max-width: 768px)');

	function onMediaQueryChange() {
		if (mediaQuery.matches) {
			stopQuotePicker()
			return enableSwiper();
		} else {
			randomQuoteTimer = setInterval(randomQuotePicker,randomQuoteInterval);
			if (mySwiper !== undefined) mySwiper.destroy(true, true);
		}
	}

	function run() {
		mediaQuery.onchange = onMediaQueryChange;
		onMediaQueryChange();
	}
	run() // run on load...

}

export default clientsGallery
