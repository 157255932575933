// Vue & Any Components:
import Vue from "vue";
import SiteHeader from './vue/components/site-header';
import MobileNav from './vue/components/mobile-nav';

new Vue(SiteHeader)
new Vue(MobileNav)

// AlpineJS
import Alpine from 'alpinejs'
window.Alpine = Alpine

import work from './alpine/work'
Alpine.data('work', work)

// AlpineJS: Start
Alpine.start()

// kicks off the barba stuff
require('./barba')
