const videos = function(next) {

	// Setup initial var...
	let videos = next.querySelectorAll('.video-element')

	if (videos.length < 1)
		return // No videos found, exiting...

	// Cycle through each video if there are more than one...
	for (let i = 0; i < videos.length; i++) {

		let el = videos[i]

		var playerSrc = 'https://iframe.videodelivery.net/',
			videoId = el.dataset.video + '?',
			poster = (el.dataset.poster) ? 'poster='+el.dataset.poster+'&' : '',
			autoplay = (el.dataset.autoplay) ? 'autoplay=true&' : '',
			nocontrols = (el.dataset.nocontrols) ? 'controls=false&' : '',
			muted = (el.dataset.muted) ? 'muted=true&' : ''
		if (el.dataset.poster) {
			playerSrc = playerSrc + videoId + poster + autoplay + nocontrols + muted
		} else {
			playerSrc = playerSrc + videoId + autoplay + nocontrols + muted
		}

		// Create the stream element
		var stream = document.createElement('iframe')
		stream.setAttribute('src', playerSrc)
		stream.setAttribute('controls', false)
		stream.setAttribute('allow', 'accelerometer; gyroscope; encrypted-media; picture-in-picture;')
		stream.setAttribute('allowfullscreen', true)
		stream.setAttribute('id', 'stream-player-' + i)
		// Append it into the video container
		el.append(stream)

	}
	
}
export default videos
