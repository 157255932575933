
export default {
	namespace: 'default',
	beforeEnter() {

	},
	afterEnter() {

	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
