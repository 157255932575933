import maqruee from 'vanilla-marquee'

const scrollMarquee = function(container) {

	// Search for any scrolling marquees on current page...
	let marquees = container.querySelectorAll('.marquee-text-wrapper')

	if (marquees.length < 1)
		return // No marquees found, exiting...

	// Only run if a scrolling marquee is found...
	marquees.forEach(m => {

		new maqruee( m, {
			duplicated: true,
			pauseOnHover: true,
			duration: 5000,
			recalcResize: true
		})

	});

}

export default scrollMarquee
