import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

let tl = gsap.timeline()

export default {
	namespace: 'project',
	beforeEnter() {

	},
	afterEnter(barba){

		let page = barba.next.container.querySelector('#page'),
			dimmer = barba.next.container.querySelector('.dimmer')
			
		setTimeout(function() {

			tl.to(dimmer, { 
				scrollTrigger: {
					trigger: page,
					pin: true,
					start: "top top",
					end: "+=100%",
					scrub: 1
				},
				autoAlpha: 0
			})
		}, 100)

	},
	beforeLeave(){

	},
	afterLeave() {

		let ScrollTriggers = ScrollTrigger.getAll()
		ScrollTriggers[0].kill(true)

	}
}
