import Swiper from 'swiper';

const moreWork = function(next) {

	// Search for any more work galleries on current page...
	let galleries = document.getElementsByClassName('more-work')

	// Loop through each gallery el...
	for (let gallery of galleries) {

		let slider = gallery.querySelector('.swiper-container')

		let swiper = new Swiper(slider, {
			slidesPerView: "auto",
			centeredSlides: false
		})

	}

}

export default moreWork
