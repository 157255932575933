import Swiper from 'swiper';

const gallery = function(next) {

	// Search for any galleries on current page...
	let galleries = document.getElementsByClassName('gallery')

	// Loop through each gallery el...
	for (let gallery of galleries) {


		let slider = gallery.querySelector('.swiper-container')

		let swiper = new Swiper(slider, {
			slidesPerView: "auto",
			spaceBetween: 7,
			centeredSlides: true,
			breakpoints: {
				640: {
					spaceBetween: 30
				}
			}
		})

	}

}

export default gallery
