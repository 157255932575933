const activeNav = function(path) {

	// Get global site nav, built in partials/nav-js.pug...
	let nav = window.site.nav

	// Loop through each nav link and apply active state...
	nav.forEach(link => {
		(link.url.split('/')[1] === path.split('/')[1]) ? link.active = true : link.active = false
	})

}

export default activeNav
