import { gsap } from "gsap";

var tl = gsap.timeline()

export default {

	beforeEnter({ next }) {
		console.log('before enter')
		// hide the new container
		gsap.set(next.container, { opacity: 0 })
	},

	enter({ next }) {
		console.log('enter')
	},

	afterEnter({ next }) {
		console.log('after enter')
		// since gsap doesnt generate promises, use this.async() to get a done() function to call onComplete
		const done = this.async()

		// do the tween, calling done() onComplete
		gsap.to(next.container, 0, { opacity: 1, onComplete: () => done() })
		// do any cleanup for the enter animation if needed
		tl.set('#loading-screen', { transformOrigin: 'bottom' })
		tl.to('#loading-screen', { duration: 0.5, delay: 0.15, scaleY: "0", ease:"Expo.easeInOut"})
	},

	beforeLeave({ current }) {
		console.log('before leave')
		// initialize leave animation
		tl.set('#loading-screen', { transformOrigin: 'top' })
		// tl.to('#loading-screen', { duration: 0.85,  scaleY: "1", ease:"Expo.easeInOut"})
		gsap.to(current.container, 0, { y: '0' })
	},

	leave({ current }) {
		console.log('leave')
		// since gsap doesnt generate promises, use this.async() to get a done() function to call onComplete
		const done = this.async()

		tl.to('#loading-screen', { duration: 0.85, scaleY: "1", ease: "Expo.easeInOut", onComplete: () => done()})

		// do the tween, calling done() onComplete
		gsap.to(current.container, 0.5, { ease: "Power2.easeInOut" })
	},

	afterLeave({ current }) {
		console.log('after leave')
		// clean up after leave animation
		window.scrollTo(0,0)
	}
}
