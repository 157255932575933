const axios = require('axios');
const isotopeLayout = require('isotope-layout')
import aos from '../components/aos'
import cursorButton from '../components/cursor-button'

export default {
	namespace: 'work',
	beforeEnter(barba) {

		aos(barba.next.container)

		const element = barba.next.container.querySelector('#projectsList');

		const filterGrid = new isotopeLayout(element, {
			itemSelector: '.project-wrapper',
			layoutMode: 'fitRows',
			fitRows: {
				gutter: 96
			}
		});

		let projectFilterBtns = barba.next.container.querySelectorAll('.project-filter-btn')

		projectFilterBtns.forEach(btn => {
			btn.addEventListener('click', (e) => {
				e.preventDefault()
				let filter = btn.dataset.filter
				if (filter == 'all') {
					filterGrid.arrange({ filter: '*' })
				} else {
					filterGrid.arrange({
						filter: function(itemElem) {
							// Get the value of the data-category attribute for this item
							var categories = itemElem.getAttribute('data-categories');
							var categoryArray = categories.split(' ');
							// Check if the filter is included in the category array
							return categoryArray.includes(filter);
						}
					});
				}
			})
		})

		// on load filter if there is a filter in the url
		let url = new URL(window.location.href)
		let path = url.pathname
		if (path.includes('/filter/')) {
			let filter = path.split('/').pop()
			if (filter) {
				if (filter == 'all') {
					filterGrid.arrange({ filter: '*' })
				} else {
					filterGrid.arrange({
						filter: function (itemElem) {
							// Get the value of the data-category attribute for this item
							var categories = itemElem.getAttribute('data-categories');
							var categoryArray = categories.split(' ');
							// Check if the filter is included in the category array
							return categoryArray.includes(filter);
						}
					});
				}
			}
		}



		// let loadProjectsBtn = barba.next.container.querySelector('#loadProjects'),
		// 	projectsList = barba.next.container.querySelector('#projectsList'),
		// 	url = loadProjectsBtn.dataset.href,
		// 	projectsCount = parseInt(loadProjectsBtn.dataset.projects)
			

		// loadProjectsBtn.addEventListener("click", (e) => {
		// 	let offset = parseInt(loadProjectsBtn.dataset.offset),
		// 		items = parseInt(loadProjectsBtn.dataset.items)

		// 	e.preventDefault()
		// 	axios.get(url + '?limit=' + items + '&offset=' + offset)
		// 		.then((response) => {

		// 			let parser = new DOMParser(),
		// 				doc = parser.parseFromString(response.data, 'text/html'),
		// 				newWork = doc.querySelectorAll('.project-wrapper')

		// 			newWork.forEach(item => {
		// 				projectsList.appendChild(item)
		// 			})			

		// 			loadProjectsBtn.dataset.offset = (offset + items)// update the Load More Button offset by the new offset

		// 			if ((offset + items) >= projectsCount) {
		// 				loadProjectsBtn.remove() // remove Load more button once we've reached the limit...
		// 			}
					
		// 		})
		// 		.catch((error) => {
		// 			console.log(error)
		// 		})
		// 		.then(() => {
		// 			aos(barba.next.container)
		// 			cursorButton(barba.next.container)
		// 		})
		// });

	},
	afterEnter(){

	},
	beforeLeave(){

	},
	afterLeave() {

	}
}
